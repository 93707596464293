body {
  /*background-color: #444477 !important;*/
  height: 100%;
  /*background: #FFF  url('./images/bg.jpg') center;*/
  background: #324c78; /* Old browsers */
  background: -moz-linear-gradient(top, #aaa 0%, #344060 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #324c78 0%, #344060 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #324c78 0%,
    #344060 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#324c78', endColorstr='#344060',GradientType=0 ); /* IE6-9 */
  background-attachment: fixed;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.App {
  text-align: center;
  height: 100vh;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
